export const FeaturesWithIcons = () =>{
    return <section className="py-md-7 pb-5">
        <div className="container">
            <div className="row text-center">
                <div className="col-lg-3 col-md-6">
                    <div className="info">
                        <div className="icon icon-md icon-shape bg-gradient-primary shadow-primary text-center border-radius-xl mt-n4">
                            <i className="material-icons opacity-10">grid_view</i>
                        </div>
                        <h5 className="mt-3">Shopify (Coming Soon)</h5>
                        <p>Install our app for shopify events notifications for any event.</p>
                    </div>
                </div>
                <div className="col-lg-3 col-md-6">
                    <div className="info">
                        <div className="icon icon-md icon-shape bg-gradient-primary shadow-primary text-center border-radius-xl mt-n4">
                            <i className="material-icons opacity-10">brush</i>
                        </div>
                        <h5 className="mt-3">Price</h5>
                        <p>Affordable price, not much to worry about. </p>
                    </div>
                </div>
                <div className="col-lg-3 col-md-6">
                    <div className="info">
                        <div className="icon icon-md icon-shape bg-gradient-primary shadow-primary text-center border-radius-xl mt-n4">
                            <i className="material-icons opacity-10">code</i>
                        </div>
                        <h5 className="mt-3">Less Code</h5>
                        <p>Easy access to API's through simple registration</p>
                    </div>
                </div>
                <div className="col-lg-3 col-md-6">
                    <div className="info">
                        <div className="icon icon-md icon-shape bg-gradient-primary shadow-primary text-center border-radius-xl mt-n4">
                            <i className="material-icons opacity-10">devices</i>
                        </div>
                        <h5 className="mt-3">Automatic Scaling</h5>
                        <p>Not to worry about notifications, your business is our top priority. No limitaion on traffic</p>
                    </div>
                </div>
            </div>
        </div>
    </section>

}