export const Footer = () =>{
    return <footer className="footer py-5 bg-gradient-dark position-relative overflow-hidden">
        <img src="../assets/img/shapes/waves-white.svg" alt="pattern-lines" className="position-absolute start-0 top-0 w-100 opacity-1" loading="lazy" />
        <div className="container position-relative">
            <div className="row">
                <div className="col-lg-4 me-auto my-auto text-lg-start text-center">
                    <h6 className="text-white font-weight-bold text-uppercase mb-2">NotifyToday</h6>
                    <p className="text-white">
                        Copyright © {
                            new Date().getFullYear()
                        } NotifyToday
                    </p>
                </div>
                <div className="col-lg-2 col-sm-4 col-12 ms-md-auto text-lg-start text-center mb-sm-0 mb-4">
                    <div>
                        <h6 className="text-white text-sm mb-1">Company</h6>
                        <ul className="flex-column ms-lg-n3 nav">
                            {/* <li className="nav-item">
                                <a className="nav-link text-white opacity-8 text-sm pb-0" href="javascript:;" target="_blank">
                                    About Us
                                </a>
                            </li> */}
                            <li className="nav-item">
                                
                                <a className="nav-link text-white opacity-8 text-sm pb-0" href="https://calendly.com/vishal-sharma-full-stack-developer/30min" target="_blank" rel="noreferrer">
                                    Book a call
                                </a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link text-white opacity-8 text-sm pb-0" href="https://www.linkedin.com/in/vishal-sharma-gbpecdelhi/" target="_blank" rel="noreferrer">
                                Connect with Me
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
                {/* <div className="col-lg-2 col-sm-4 col-12 text-lg-start text-center mb-sm-0 mb-4">
                    <div>
                        <h6 className="text-white text-sm mb-1">Pages</h6>
                        <ul className="flex-column ms-lg-n3 nav">
                            <li className="nav-item">
                                <a className="nav-link text-white opacity-8 text-sm pb-0" href="javascript:;" target="_blank">
                                    Login
                                </a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link text-white opacity-8 text-sm pb-0" href="javascript:;" target="_blank">
                                    Register
                                </a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link text-white opacity-8 text-sm pb-0" href="javascript:;" target="_blank">
                                    About
                                </a>
                            </li>
                        </ul>
                    </div>
                </div> */}
                {/* <div className="col-lg-2 col-sm-4 col-12 text-lg-start text-center mb-sm-0 mb-4">
                    <div>
                        <h6 className="text-white text-sm mb-1">Products</h6>
                        <ul className="flex-column ms-lg-n3 nav">
                            <li className="nav-item">
                                <a className="nav-link text-white opacity-8 text-sm pb-0" href="javascript:;" target="_blank">
                                    Free
                                </a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link text-white opacity-8 text-sm pb-0" href="javascript:;" target="_blank">
                                    PRO
                                </a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link text-white opacity-8 text-sm pb-0" href="javascript:;" target="_blank">
                                    Latest
                                </a>
                            </li>
                        </ul>
                    </div>
                </div> */}
            </div>
        </div>
    </footer>
}