import { Footer } from "../components/Footer"
import { NavBar } from "../components/NavBar"
import { Header } from "../components/Header"
import { FeaturesWithIcons } from "../components/FeaturesWithIcons"
import { TopAndLeftSideImageComponent } from "../components/TopAndLeftSideImageComponent"
import { Reviews } from "../components/Reviews"
import { FeaturesWithImageCard } from "../components/FeaturesWithImageCard"

export const HomePage = () => {
    return <div className="case-study bg-gray-200">
        <NavBar />
        <Header />
        <FeaturesWithIcons />
        <TopAndLeftSideImageComponent />
        <Reviews />
        <FeaturesWithImageCard />
        <Footer />
    </div>
}