import notifyTodayChatBanner from '../assets/img/notifytoday-chat-banner-notification.png'

export const Header = () => {

    return <header>
        <div className="page-header min-vh-85">
            <div>
                <img className="position-absolute fixed-top ms-auto w-50 h-100 z-index-0 d-none d-sm-none d-md-block border-radius-section border-top-end-radius-0 border-top-start-radius-0 border-bottom-end-radius-0" src={notifyTodayChatBanner} alt="landscape" />
            </div>
            <div className="container">
                <div className="row">
                    <div className="col-lg-7 d-flex">
                        <div className="card card-body blur text-md-start text-center px-sm-5 shadow-lg mt-sm-5 py-sm-5">
                            <h2 className="text-dark mb-0">NotifyToday</h2>
                            <h3 className="text-dark mb-4">Solution for Notification Hussle</h3>
                            <p className="lead text-dark pe-md-5 me-md-5">
                                It's not just me or you; we all need some reminders. Our customers (businesses) are also not different.
                            </p>
                            <p>
                                <b>For your every event,</b> <i>we are ready to celebrate it with your notifications on multiple Channel.</i>
                            </p>
                            <div className="buttons">
                                {/* <button type="button" className="btn btn-rounded bg-gradient-primary mt-4" onClick="window.open('https://calendly.com/vishal-sharma-full-stack-developer/30min')"> */}
                                <a className="btn btn-rounded bg-gradient-primary mt-4" href="https://calendly.com/vishal-sharma-full-stack-developer/30min" target="_blank" rel="noreferrer">Book a Meet</a>
                                {/* </button> */}
                                {/* <button type="button" className="btn btn-rounded btn-outline-secondary mt-4 ms-2"> */}
                                <a className="btn btn-rounded btn-outline-secondary mt-4 ms-2" href="https://www.linkedin.com/in/vishal-sharma-gbpecdelhi/" target="_blank" rel="noreferrer">Connect With Me</a>
                                {/* </button> */}
                            </div>
                            <div className="row mt-2">
                                {/* <div className="col-md-3 col-6 p-0">
                                        <img className="w-100" src="./assets/img/logos/gray-logos/logo-pinterest.svg" alt="logo" />
                                    </div>
                                    <div className="col-md-3 col-6 p-0">
                                        <img className="w-100" src="../assets/img/logos/gray-logos/logo-netflix.svg" alt="logo" />
                                    </div>
                                    <div className="col-md-3 col-6 p-0">
                                        <img className="w-100" src="../assets/img/logos/gray-logos/logo-coinbase.svg" alt="logo" />
                                    </div>
                                    <div className="col-md-3 col-6 p-0">
                                        <img className="w-100" src="../assets/img/logos/gray-logos/logo-nasa.svg" alt="logo" />
                                    </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </header>
}