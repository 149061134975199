import logo from '../assets/logo/notify-today-logo-full.png'
import mobileWhatsapp from '../assets/img/whatsapp-statistics.png'
import notificationFailure from '../assets/img/mobile_whatsapp.png'


export const TopAndLeftSideImageComponent = () =>{
    return <section>
        <div className="container">
            <div className="row">
                <div className="col-md-6 ms-auto me-auto text-center">
                    <h2>Everyone need reminders</h2>
                    <p>
                        It is not just customers; it can be your team as well. You can use our platform for internal notification of a particular event.
                    </p>
                </div>
            </div>
            <div className="row mt-5">
                <div className="col-md-6 position-relative">
                    <div className="position-relative ms-md-5 me-md-n5">
                        <div className="blur-shadow-image">
                            <img className="image-left border-radius-lg img-fluid position-relative top-0 end-0 bg-cover" src={logo} alt="NotifyToday" loading="lazy" />
                        </div>
                    </div>
                    <p className="blockquote border border-primary rounded w-50 p-3 text-sm text-primary float-md-end mt-4 me-md-n2 mx-auto">
                        I don't sleep so that my lord could have error-free sleep. Your satisfaction is only status OK 200 for me.
                        <br />
                        <br />
                        <small>- NotifyToday Bot</small>
                    </p>

                    <div className="position-absolute bottom-0 mb-5 ms-n2 me-3 start-0 end-2 d-md-block d-none">
                        <div className="blur-shadow-image">
                            <img className="image-container border-radius-lg img-fluid position-relative bg-cover" src={notificationFailure} alt="notification-failure" loading="lazy" />
                        </div>
                    </div>
                </div>
                <div className="col-md-5">

                    <div className="position-relative ms-n4 mb-5 mt-8 d-md-block d-none">
                        <div className="blur-shadow-image">
                            <img className="image-right border-radius-lg img-fluid position-relative bg-cover" src={mobileWhatsapp} alt="whatsapp" loading="lazy" />
                        </div>
                    </div>
                    <h3 className="mb-4">So what are we going to do with NotifyToday?</h3>
                    <p>
                        Our vision is to create a single platform for all your notification needs, whether it's an internal notification alert or a business-specific alert to customers.
                    </p>
                    <p>
                        We will not only solve your notification problem, but we will also be providing you analytics on notifications so that you can optimise your business better.
                    </p>
                    <p>
                        At the same time, we are trying to build a system that doesn't compromise on reliability, cost, or integration.
                    </p>
                    <p>
                        We value data privacy just as much as you do, which is why we prefer encryption so that all conversations remain between you and your customers.
                    </p>
                </div>
            </div>
        </div>
    </section>
}