export const FeaturesWithImageCard = () => {
    return <section className="py-7">
        <div className="container">
            <div className="row">
                <div className="col-md-5 ms-auto me-auto text-center">
                    <div className="icon icon-md icon-shape bg-gradient-primary shadow-primary text-center border-radius-xl mt-n4">
                        <i className="material-icons opacity-10">engineering</i>
                    </div>
                    <h3 className="text-dark mb-0 mt-3">What are offering to you</h3>
                    <p>We’re constantly trying to express ourselves and actualize our dreams. Don't stop.</p>
                </div>
            </div>
            <div className="row mt-5">
                <div className="col-md-4 ms-auto my-auto">
                    <div className="cursor-pointer">
                        <div className="card card-background">
                            <div className="full-background" style={{ backgroundImage: "url('https://images.unsplash.com/photo-1587614203976-365c74645e83?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=934&q=80')" }}></div>
                            <div className="card-body pt-7 text-center">
                                <p className="text-white text-uppercase">Tech First Team!</p>
                                <h3 className="text-white up mb-0">The Most Important New Technology</h3>
                                <p className="text-white opacity-8">We’re constantly trying to express ourselves and actualize our dreams.</p>
                                <button type="button" className="btn btn-white btn-sm mt-3">
                                    <a href="https://calendly.com/vishal-sharma-full-stack-developer/30min" target="_blank" rel="noreferrer">Book a Call</a>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-5 me-auto my-auto ms-md-5">
                    <div className="p-3 info-horizontal d-flex">
                        <div>
                            <h5>1. State of Art Whatsapp FeedBack System</h5>
                            <p>
                                You can customise your feedback question and format with just a single click. You would be able to collect feedback, regardless of the number of messages between the question and the answer, for one year.
                            </p>
                        </div>
                    </div>
                    <div className="p-3 info-horizontal d-flex">
                        <div>
                            <h5>2. Whatsapp poll </h5>
                            <p>
                                Don't know what your users want? Don't forward messages on WhatsApp; we are here to help you out and collect opinions directly from customers.
                            </p>
                        </div>
                    </div>
                    <div className="p-3 info-horizontal d-flex">
                        <div>
                            <h5>3. Hire Dedicated Conversation Manager</h5>
                            <p>
                                If your company is expanding but you are unable to handle customer inquiries, our agent will assist you in having a fruitful conversation with customers.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <hr className="dark my-md-6 mt-2 mx-7" />
            <div className="row">
                <div className="col-md-5 ms-auto my-auto">
                    <div className="p-3 info-horizontal d-flex">
                        <div>
                            <h5>4. Scheduled Notification</h5>
                            <p>
                                NotifyToday, no matter where you are, helps you live a stress-free, timeless lifestyle by allowing you to schedule notifications for the future and access them at any time, from anywhere.
                            </p>
                        </div>
                    </div>
                    <div className="p-3 info-horizontal d-flex">
                        <div>
                            <h5>5. OTP Authentication</h5>
                            <p>
                                Send your OTP message not just through SMS but also on WhatsApp, SMS, or email without hassle.
                            </p>
                        </div>
                    </div>
                    <div className="p-3 info-horizontal d-flex">
                        <div>
                            <h5>6. Single Click Installation</h5>
                            <p>
                                Integrate your store with a single click on the Shopify app store's "add app" button; it's as simple as it sounds.
                            </p>
                        </div>
                    </div>
                </div>
                <div className="col-md-4 me-auto my-auto ms-md-5">
                    <div className="cursor-pointer">
                        <div className="card card-background">

                            <div className="full-background" style={{ backgroundImage: "url('https://images.unsplash.com/photo-1507207611509-ec012433ff52?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=934&q=80')" }}></div>

                            <div className="card-body pt-7 text-center">
                                <p className="text-white text-uppercase">Meet and Chat!</p>
                                <h3 className="text-white mb-0">How do Notifications Make the World Bigger</h3>
                                <p className="text-white opacity-8">That’s what I do. That’s what I’m here for. Don’t be afraid to be wrong because of your opinion</p>
                                <button type="button" className="btn btn-white btn-sm mt-3">
                                    <a href="https://www.linkedin.com/in/vishal-sharma-gbpecdelhi/" target="_blank" rel="noreferrer">Connect With Me</a>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
}