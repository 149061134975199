import notifyTodayFavicon from '../assets/favicons/favicon-32x32.png'

export const Reviews = () =>{
    return <section className="bg-gradient-dark position-relative overflow-hidden mt-5">
        <img src="../assets/img/shapes/waves-white.svg" alt="pattern-lines" className="position-absolute opacity-1 w-100" />
        <div className="container py-lg-4 py-2">
            <div className="row">
                <div className="col-lg-6 d-flex justify-content-center flex-column">
                    <div id="carouselExampleIndicator" className="carousel slide py-7" data-bs-ride="carousel">
                        <div className="carousel-indicators justify-content-start ms-0">
                            <div>
                                <img src="../assets/img/team-4.jpg" alt="..." className="avatar avatar-sm avatar-scale-up border-radius-lg shadow border-0 active" data-bs-target="#carouselExampleIndicator" data-bs-slide-to="0" />
                                <span className="text-white mx-2">&#124;</span>
                            </div>
                            <div>
                                <img src="../assets/img/team-3.jpg" alt="..." className="avatar avatar-sm avatar-scale-up border-radius-lg shadow border-0" data-bs-target="#carouselExampleIndicator" data-bs-slide-to="1" />
                                <span className="text-white mx-2">&#124;</span>
                            </div>
                            <div>
                                <img src="../assets/img/team-2.jpg" alt="..." className="avatar avatar-sm avatar-scale-up border-radius-lg shadow border-0" data-bs-target="#carouselExampleIndicator" data-bs-slide-to="2" />
                            </div>
                        </div>
                        <div className="carousel-inner">
                            <h2 className="text-white mb-0">Work with us</h2>
                            <p className="text-white opacity-8 mb-1">If your Store based on Shopify - we got you!</p>
                            <hr className="text-white horizontal opacity-6 mb-4 mt-2 w-25 text-start" />
                            <div className="carousel-item active">
                                <h6 className="text-white opacity-8 pe-5">"In the initial phase, we are coming up with multiple channels such as WhatsApp, Instagram, Telegram, and Slack."</h6>
                                <div className="author mt-4">
                                    <div className="name">
                                        <span className="text-white">NotifyToday - Platform for Notification Needs</span>
                                        <div className="stats">
                                            <small className="text-white opacity-6">Coming Soon(Testing system for smoothness)</small>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="carousel-item">
                                <h6 className="text-white opacity-8 pe-5">"Sale is going on, But if you don't know how to alert customers that something significant is happening, we're here to help. "</h6>
                                <div className="author mt-4">
                                    <div className="name">
                                        <span className="text-white">NotifyToday - Platform for Notification Needs</span>
                                        <div className="stats">
                                            <small className="text-white opacity-6">Coming Soon(Testing system for smoothness)</small>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="carousel-item">
                                <h6 className="text-white opacity-8 pe-5">"We are not just for customers; we can help you with internal team notifications such as inventory alerts, orders placed by customers, etc. "</h6>
                                <div className="author mt-4">
                                    <div className="name">
                                        <span className="text-white">NotifyToday - Platform for Notification Needs</span>
                                        <div className="stats">
                                            <small className="text-white opacity-6">Coming Soon(Testing system for smoothness)</small>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-6 justify-content-center flex-column d-sm-none d-md-none d-lg-flex d-xl-flex">
                    <div className="row justify-content-center d-none d-md-flex">
                        <div className="col-3 mb-4">
                            <div className="d-block bg-white avatar avatar-lg border-radius-lg p-3 mt-n4 ms-8 fadeIn2 fadeInBottom">
                                <img src="../assets/img/logos/small-logos/logo-slack.svg" alt="Logo Slack" />
                            </div>
                        </div>
                        <div className="col-4 mb-4">
                            <div className="d-block bg-white avatar avatar-lg border-radius-lg p-3 ms-8 mt-n6 fadeIn1 fadeInBottom">
                                <img src="../assets/img/logos/small-logos/logo-shopify.svg" alt="Logo Shopify" />
                            </div>
                        </div>
                        <div className="col-4 mb-4">
                            <div className="d-block bg-white avatar avatar-lg border-radius-lg p-3 ms-6 mt-2 fadeIn3 fadeInBottom">
                                <img src="../assets/img/logos/small-logos/logo-amazon.svg" alt="Logo Amazon" />
                            </div>
                        </div>
                    </div>
                    <div className="row justify-content-end d-none d-md-flex">
                        <div className="col-4 my-4">
                            <div className="d-block bg-white avatar avatar-lg border-radius-lg p-3 ms-4 fadeIn1 fadeInBottom">
                                <img className="avatar-img" src="../assets/img/logos/small-logos/logo-amazon.svg" alt="Logo Amazon" />
                            </div>
                        </div>
                        <div className="col-3 my-4">
                            <div className="d-block bg-white avatar avatar-lg border-radius-lg p-3 me-auto fadeIn1 fadeInBottom">
                                <img className="avatar-img" src={notifyTodayFavicon} alt="notifyToday favicon" />
                            </div>
                        </div>
                        <div className="col-3 my-4">
                            <div className="d-block bg-white avatar avatar-lg border-radius-lg p-3 fadeIn3 fadeInBottom ms-3 mt-3">
                                <img className="avatar-img" src="../assets/img/logos/small-logos/logo-shopify.svg" alt="Logo Shopify" />
                            </div>
                        </div>
                    </div>
                    <div className="row d-none d-md-flex">
                        <div className="col-6">

                            <div className="d-block bg-white avatar avatar-lg border-radius-lg p-3 ms-auto mt-5 fadeIn2 fadeInBottom">
                                <img className="avatar-img" src="../assets/img/logos/small-logos/logo-github.svg" alt="Logo Github" />
                            </div>

                        </div>
                        <div className="col-6 mt-6">

                            <div className="d-block bg-white avatar avatar-lg border-radius-lg p-3 mx-auto mt-n3 fadeIn3 fadeInBottom">
                                <img className="avatar-img" src="../assets/img/logos/small-logos/logo-slack.svg" alt="Logo Slack" />
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
}